.root {
  position: relative;
  margin-bottom: clamp(2rem, 4%, 3.5rem);
  padding-bottom: clamp(2rem, 4%, 3.5rem);
  background: #050505;
  color: #fff;
}
.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -5px;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%,  rgba(0,0,0,0.7) 10%, rgba(0,0,0,0) 20%);
}
.root.alignCenter {
  align-items: center;
}

.root.spacingNone {
  margin-bottom: 0;
}

.container {
  z-index: 20;
}

.bgImgWrap {
  position: relative;
  line-height: 0;
}

.bgImgWrap::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 35%;
  background: linear-gradient(
    to bottom,
    rgba(5, 5, 5, 0) 0%,
    rgba(5, 5, 5, 0.0086472) 6.67%,
    rgba(5, 5, 5, 0.03551) 13.33%,
    rgba(5, 5, 5, 0.0816599) 20%,
    rgba(5, 5, 5, 0.147411) 26.67%,
    rgba(5, 5, 5, 0.231775) 33.33%,
    rgba(5, 5, 5, 0.331884) 40%,
    rgba(5, 5, 5, 0.442691) 46.67%,
    rgba(5, 5, 5, 0.557309) 53.33%,
    rgba(5, 5, 5, 0.668116) 60%,
    rgba(5, 5, 5, 0.768225) 66.67%,
    rgba(5, 5, 5, 0.852589) 73.33%,
    rgba(5, 5, 5, 0.91834) 80%,
    rgba(5, 5, 5, 0.96449) 86.67%,
    rgba(5, 5, 5, 0.991353) 93.33%,
    #050505 100%
  );
}

.logoWrap {
  max-width: 380px;
  max-height: 128px;
  margin: 0 auto 1.5rem;
  margin: -24px auto 0;
}

.logoWrap img {
  object-position: bottom center;
}

.content {
  max-width: 520px;
  margin: 0 auto;
}

@media (min-width: 1000px) {
  .root {
    display: flex;
    position: relative;
    align-items: flex-end;
    height: 630px;
  }

  .root.alignCenter {
    padding-top: clamp(2rem, 4%, 3.5rem);
  }

  .container {
    width: 100%;
  }

  .bgImgWrap::after {
    top: 0;
    height: auto;
    background: linear-gradient(
      to right,
      #050505 0%,
      rgba(5, 5, 5, 0.75) 15%,
      rgba(5, 5, 5, 0.5) 30%,
      rgba(5, 5, 5, 0.25) 45%,
      rgba(5, 5, 5, 0) 55%
    );
  }

  .bgImgWrap {
    position: absolute;
    inset: 0;
  }

  .logoWrap {
    max-width: 504px;
    margin: 0;
  }

  .logoWrap img {
    object-position: bottom left;
  }

  .content {
    margin: 0;
  }
}
