.description {
  margin: 1.5rem 0;
}

.btnGroup .btn {
  width: 100%;
}

.btnGroup .btn + .btn {
  margin-top: 1rem;
}

/* Mailbag */
.mailbagBtn {
  display: flex;
  color: var(--white);
}

.mailbagBtn .iconWrap {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border: 1px solid var(--accents-8);
  border-radius: 100%;
  line-height: 32px;
  text-align: center;
  text-indent: -3px;
}

.mailbagBtn .iconWrap svg {
  margin-bottom: 6px;
  color: var(--white);
}

.mailbagBtn .heading {
  color: var(--red);
}

@media (max-width: 999px) {
  /* Mailbag */
  .mediaHero__mailbag {
    padding-bottom: 4.5rem;
  }
}

@media (min-width: 1000px) {
  .btnGroup .btn {
    width: auto;
  }

  .btnGroup .btn + .btn {
    margin: 0 0 0 1rem;
  }
}
